import React from "react";
import * as S from "../Contact/Contact.styles";

const Contact = () => {
  return (
    <S.Contact>
      <S.Text>
        Complaints/Commisions {">"}{" "}
        <S.Link href="mailto:susularoche@gmail.com">
          susularoche@gmail.com
        </S.Link>
      </S.Text>
    </S.Contact>
  );
};

export default Contact;
